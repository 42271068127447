<template>
  <div class="body container">
    <h2>{{ $t("bill.sendMail.title") }}</h2>
    <h4>{{ $t("bill.sendMail.billRecipient") }}</h4>
    <el-table
      ref="multipleTable"
      :data="users"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="60"> </el-table-column>
      <el-table-column property="name" :label="$t('column.name')">
      </el-table-column>
      <el-table-column property="mail" :label="$t('column.email')">
      </el-table-column>
    </el-table>
    <el-row class="rowGap" type="flex">
      <el-col :span="4">
        <router-link :to="{ name: 'Register' }">
          {{ $t("bill.sendMail.addRecipient") }}
        </router-link>
      </el-col>
    </el-row>
    <el-row class="rowGap" type="flex">
      <el-col :span="4">
        {{ $t("bill.sendMail.mailLanguage") }}
      </el-col>
      <el-col :span="20">
        <el-select v-model="lang">
          <el-option label="繁體中文" value="tw" />
          <el-option label="简体中文" value="cn" />
          <el-option label="English" value="en" />
        </el-select>
      </el-col>
    </el-row>
    <el-row class="rowGap" type="flex">
      <el-col :span="4">
        {{ $t("bill.sendMail.remarkInMail") }}
      </el-col>
      <el-col :span="20">
        <el-input type="textarea" :rows="2" v-model="customContent" />
      </el-col>
    </el-row>
    <el-row class="rowGap" type="flex" justify="center">
      <el-button
        type="primary"
        @click="
          () => {
            openMailPriview = true;
          }
        "
      >
        {{ $t("bill.sendMail.openMailPreview") }}
      </el-button>
    </el-row>
    <hr class="separate-line" />
    <el-row v-if="openMailPriview" class="rowGap" type="flex">
      <el-col :span="4">
        {{ $t("bill.sendMail.mailPreview") }}
      </el-col>
      <el-col :span="20">
        <MailPreview
          :lang="lang"
          :remark="customContent"
          :payBefore="$store.getters['bill/billReceivableDate']"
          :tutoringPayments="tutoringPayments"
          :paymentMethodIndex="$store.getters['bill/billPaymentIndex']"
          :paymentInfoRemark="$store.getters['bill/paymentInfoRemark']"
          :orderItems="$store.getters['bill/orderItems']"
          :billParentUserName="$store.getters['bill/billParent'].name"
          :billTotalPriceWithCurrency="
            addCurrencySymbol(
              $store.getters['bill/billTotalPrice'],
              $store.getters['bill/billCurrency']
            )
          "
          :billTitle="$store.getters['bill/billTitle']"
          :students="students"
          :balance="
            addCurrencySymbol(
              convertPriceByCurrency(
                $store.getters['bill/billParent'].balance,
                $store.getters['bill/billParent'].balanceCurrency,
                $store.getters['bill/billCurrency']
              ),
              $store.getters['bill/billCurrency']
            )
          "
          :paidBillTotalByBalanceLeft="
            $store.getters['bill/paidBillTotalByBalanceLeft']
          "
        />
      </el-col>
    </el-row>
    <el-row class="rowGap" type="flex" justify="center">
      <el-col :span="4">
        <el-button
          type="primary"
          @click="createBillAndSend"
        >
          {{ sendButtonText || $t("bill.sendMail.createBillAndSend") }}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import "@/assets/scss/style.scss";
import MailPreview from "@/components/bill/MailPreview.vue";
import profileApi from "@/apis/profile.js";
import moneyMixin from "@/mixins/money";
import { instant } from "@ivy-way/material";

export default {
  components: {
    MailPreview
  },
  mixins: [moneyMixin],
  async created() {
    await this.setDefaultLang(this.parentId);
    this.fetchParents(this.students);
  },
  props: {
    parentId: {
      type: Number,
      default: null
    },
    students: {
      type: Array,
      default: () => []
    },
    tutoringPayments: {
      type: Array,
      default: () => []
    },
    sendButtonText: {
      type: String,
      default: null
    },
    onSendMail: {
      type: Function,
      default: null
    }
  },
  computed: {
    instant() {
      return instant;
    }
  },
  watch: {
    students: {
      handler(students) {
        this.fetchParents(students);
      }
    },
    parentId: {
      async handler(parentId) {
        await this.setDefaultLang(parentId);
      }
    }
  },
  data() {
    return {
      openMailPriview: false,
      users: [],
      selectedUsers: [],
      customContent: "",
      lang: "tw"
    };
  },
  methods: {
    async setDefaultLang(parentId) {
      const {
        basic_info: { lang }
      } = await profileApi.getUserData(parentId);
      this.lang = lang || "tw";
    },
    handleSelectionChange(selectedUsers) {
      this.selectedUsers = selectedUsers;
    },
    async createBillAndSend() {
      if (this.onSendMail !== null) {
        this.onSendMail(this.selectedUsers, this.customContent, this.lang);
        return;
      }

      try {
        const { id: billId } = await this.$store.dispatch("bill/createBill");

        if (this.$route.name === "createBillWithOtherBill") {
          await this.$store.dispatch(
            "bill/deleteBill",
            this.$route.params.billId
          );
        }
        const usersMail = this.selectedUsers.map(user => user.mail);
        if (usersMail.length > 0) {
          await this.$store.dispatch("bill/sendBill", {
            billId,
            usersMail,
            customContent: this.customContent,
            lang: this.lang
          });
        }
        this.$message({
          message: `帳單已成功建立${usersMail.length > 0 ? "並送出" : ""}！`,
          type: "success"
        });
        this.$router.push({ name: "Bill" });
      } catch (e) {
        console.warn(e);
      }
    },
    async fetchParents() {
      const isExistParentsSet = new Set();
      this.users = [];
      this.$store.getters["bill/students"].forEach(async student => {
        const {
          student: { parents }
        } = await profileApi.getUserData(student.id);
        parents.forEach((parent) => {
          if (!isExistParentsSet.has(parent.email)) {
            this.users = [
              ...this.users,{
                name: `${parent.first_name} ${parent.last_name}`,
                mail: parent.email
              }
            ];
          }
          isExistParentsSet.add(parent.email);
        });
      });
    }
  }
};
</script>
