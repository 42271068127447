var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body container"},[_c('h2',[_vm._v(_vm._s(_vm.$t("bill.sendMail.title")))]),_c('h4',[_vm._v(_vm._s(_vm.$t("bill.sendMail.billRecipient")))]),_c('el-table',{ref:"multipleTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.users},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"60"}}),_c('el-table-column',{attrs:{"property":"name","label":_vm.$t('column.name')}}),_c('el-table-column',{attrs:{"property":"mail","label":_vm.$t('column.email')}})],1),_c('el-row',{staticClass:"rowGap",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":4}},[_c('router-link',{attrs:{"to":{ name: 'Register' }}},[_vm._v(" "+_vm._s(_vm.$t("bill.sendMail.addRecipient"))+" ")])],1)],1),_c('el-row',{staticClass:"rowGap",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":4}},[_vm._v(" "+_vm._s(_vm.$t("bill.sendMail.mailLanguage"))+" ")]),_c('el-col',{attrs:{"span":20}},[_c('el-select',{model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}},[_c('el-option',{attrs:{"label":"繁體中文","value":"tw"}}),_c('el-option',{attrs:{"label":"简体中文","value":"cn"}}),_c('el-option',{attrs:{"label":"English","value":"en"}})],1)],1)],1),_c('el-row',{staticClass:"rowGap",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":4}},[_vm._v(" "+_vm._s(_vm.$t("bill.sendMail.remarkInMail"))+" ")]),_c('el-col',{attrs:{"span":20}},[_c('el-input',{attrs:{"type":"textarea","rows":2},model:{value:(_vm.customContent),callback:function ($$v) {_vm.customContent=$$v},expression:"customContent"}})],1)],1),_c('el-row',{staticClass:"rowGap",attrs:{"type":"flex","justify":"center"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":() => {
          _vm.openMailPriview = true;
        }}},[_vm._v(" "+_vm._s(_vm.$t("bill.sendMail.openMailPreview"))+" ")])],1),_c('hr',{staticClass:"separate-line"}),(_vm.openMailPriview)?_c('el-row',{staticClass:"rowGap",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":4}},[_vm._v(" "+_vm._s(_vm.$t("bill.sendMail.mailPreview"))+" ")]),_c('el-col',{attrs:{"span":20}},[_c('MailPreview',{attrs:{"lang":_vm.lang,"remark":_vm.customContent,"payBefore":_vm.$store.getters['bill/billReceivableDate'],"tutoringPayments":_vm.tutoringPayments,"paymentMethodIndex":_vm.$store.getters['bill/billPaymentIndex'],"paymentInfoRemark":_vm.$store.getters['bill/paymentInfoRemark'],"orderItems":_vm.$store.getters['bill/orderItems'],"billParentUserName":_vm.$store.getters['bill/billParent'].name,"billTotalPriceWithCurrency":_vm.addCurrencySymbol(
            _vm.$store.getters['bill/billTotalPrice'],
            _vm.$store.getters['bill/billCurrency']
          ),"billTitle":_vm.$store.getters['bill/billTitle'],"students":_vm.students,"balance":_vm.addCurrencySymbol(
            _vm.convertPriceByCurrency(
              _vm.$store.getters['bill/billParent'].balance,
              _vm.$store.getters['bill/billParent'].balanceCurrency,
              _vm.$store.getters['bill/billCurrency']
            ),
            _vm.$store.getters['bill/billCurrency']
          ),"paidBillTotalByBalanceLeft":_vm.$store.getters['bill/paidBillTotalByBalanceLeft']}})],1)],1):_vm._e(),_c('el-row',{staticClass:"rowGap",attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":4}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.createBillAndSend}},[_vm._v(" "+_vm._s(_vm.sendButtonText || _vm.$t("bill.sendMail.createBillAndSend"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }