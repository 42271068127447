<template>
  <div>
    <h2>{{ $t("transaction.form.inputPayTransaction") }}</h2>
    <TransactionForm
      @handleOk="handleOk"
      :price="$store.getters['bill/billTotalPrice']"
      :currency="$store.getters['bill/billCurrency']"
      :notePlaceholder="
        $t('transaction.form.bothTheAdminAndTheParentCanSeeThis')
      "
    />
  </div>
</template>

<script>
import moment from "moment";
import TransactionForm from "@/components/bill/TransactionForm";

export default {
  components: {
    TransactionForm
  },
  props: {
    existBillId: {
      default: null,
      type: String
    }
  },
  methods: {
    async createTransaction(billId, transaction) {
      const { price, currency, isPaid } = transaction;

      const exchangeRate = this.$store.getters["currency/exchangeRate"];
      const changeToBillCurrencyprice =
        (price * exchangeRate[currency].TWD) /
        exchangeRate[this.$store.getters["bill/billCurrency"]].TWD;

      const formattedTransaction = {
        ...transaction,
        price: changeToBillCurrencyprice,
        original_price: price,
        original_currency: currency,
        is_paid: isPaid,
        datetime: moment(transaction.date).format("YYYY-MM-DD HH:mm:SS")
      };

      await this.$store.dispatch("bill/createTransaction", {
        billId,
        transaction: formattedTransaction
      });
    },
    async handleOk(transaction) {
      if (!this.existBillId) {
        try {
          const bill = await this.$store.dispatch("bill/createBill");
          await this.createTransaction(bill.id, transaction);
          await this.$store.dispatch("bill/markPaid", bill);
          this.$router.push({ name: "Bill" });
        } catch (e) {
          console.warn(e);
        }
      } else {
        await this.createTransaction(this.existBillId, transaction);
        this.$emit("handleOk");
      }
    }
  }
};
</script>
