<template>
  <div class="container">
    <div class="title">
      <img
        class="logo"
        src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo.png"
      />
      <span>{{ moment().format("MMM Do, YYYY") }}</span>
    </div>
    <p>Dear {{ billParentUserName }}{{ previewLang[lang].greetings }}</p>
    <p>
      {{ previewLang[lang].titlePartA }} <strong>{{ billTitle
      }}</strong>{{ previewLang[lang].titlePartB }}
    </p>
    <table>
      <tbody>
        <tr>
          <td>{{ previewLang[lang].column.student }}</td>
          <td>{{ previewLang[lang].column.item }}</td>
          <td>{{ previewLang[lang].column.amount }}</td>
        </tr>
      </tbody>
      <tbody>
        <tr
          :key="orderItem.id"
          v-for="orderItem in filterSelected(orderItems)"
          style="margin: 10px 0px;"
        >
          <td>{{ orderItem.studentName }}</td>
          <td>{{ orderItem.title }}</td>
          <td>{{ orderItem.displayPriceByCurrency }}</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td></td>
          <td>{{ previewLang[lang].total }}</td>
          <td>{{ billTotalPriceWithCurrency }}</td>
        </tr>
        <template v-if="isPayByBalance">
          <tr style="border: none">
            <td></td>
            <td>{{ previewLang[lang].balance }}</td>
            <td>{{ balance }}</td>
          </tr>
          <tr style="font-weight: bold;">
            <td></td>
            <td>{{ previewLang[lang].balanceLeft }}</td>
            <td>{{ paidBillTotalByBalanceLeft }}</td>
          </tr>
        </template>
      </tbody>
    </table>
    <br />
    <div class="center">
      <div>
        <button style="background: rgb(0, 167, 76)">
          {{ previewLang[lang].check }}
        </button>
      </div>
    </div>
    <br />
    <template v-if="!isPayByBalance">
      <p>
        {{ previewLang[lang].anyIssue }}
      </p>
      <div class="center">
        <div>
          <button style="background: rgb(229, 52, 73)">
            {{ previewLang[lang].issue }}
          </button>
        </div>
      </div>
      <br />
    </template>
    <p v-if="!isPayByBalance">
      {{ previewLang[lang].paymentPartA }}<strong v-if="lang !== 'en'">{{ `${payBeforeDate} 之前` }}</strong>{{ previewLang[lang].paymentPartB }}
      <b>{{ billTotalPriceWithCurrency }}</b>
      <span v-if="isCashPayment">{{ previewLang[lang].cashPaymentPartB }}<strong v-if="lang === 'en'">{{ `before ${payBeforeDate}` }}</strong>{{ previewLang[lang].cashPaymentPartC }}</span>
      <span v-else>{{ previewLang[lang].paymentPartC }}<strong v-if="lang === 'en'">{{ `before ${payBeforeDate}` }}</strong>{{ previewLang[lang].paymentPartD }}</span>
    </p>
    <div v-if="!isCashPayment && !isPayByBalance" class="payment">
      <pre v-if="isPayByOther">{{ paymentInfoRemark }}</pre>
      <template v-else>
        <span
          >{{ previewLang[lang].accountName }}{{ billPaymentMethod.account_name
          }}<br
        /></span>
        <span v-if="billPaymentMethod.bank"
          >{{ previewLang[lang].bank }}{{ billPaymentMethod.bank }}<br
        /></span>
        <span v-if="billPaymentMethod.account_number && isMobilePayment">
          {{ previewLang[lang].account }}{{ billPaymentMethod.account_number }}
        </span>
        <span v-else-if="billPaymentMethod.account_number">
          {{ previewLang[lang].accountNumber
          }}{{ billPaymentMethod.account_number }}
        </span>
      </template>
    </div>
    <div class="remark" v-if="remark">
      <p>
        <b>{{ previewLang[lang].note }}</b
        >{{ remark }}
      </p>
    </div>
    <div class="center">
      <div>
        <button v-if="!isPayByBalance && !isCashPayment" style="background: rgb(0, 167, 76)">
          {{ previewLang[lang].pay }}
        </button>
      </div>
    </div>
    <br />
    <p v-if="!isPayByBalance" class="title">
      {{ previewLang[lang].remark }}
    </p>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    lang: {
      type: String,
      default: "en"
    },
    remark: {
      type: String,
      default: ""
    },
    payBefore: {
      type: String,
      default: ""
    },
    orderItems: {
      type: Array,
      default: () => []
    },
    billParentUserName: {
      type: String,
      default: ""
    },
    billTitle: {
      type: String,
      default: ""
    },
    billTotalPriceWithCurrency: {
      type: String,
      default: ""
    },
    tutoringPayments: {
      type: Array,
      default: () => []
    },
    paymentMethodIndex: {
      type: Number,
      default: 0
    },
    paymentInfoRemark: {
      type: String,
      default: ""
    },
    balance: {
      type: String,
      default: "$0 TWD"
    },
    paidBillTotalByBalanceLeft: {
      type: String,
      default: "$0 TWD"
    }
  },
  computed: {
    payBeforeDate() {
      return `${moment(this.payBefore).format("M")}/${moment(this.payBefore).format("D")}`;
    },
    billPaymentMethod() {
      return (
        this.tutoringPayments.find(
          tutoringPayment => tutoringPayment.index === this.paymentMethodIndex
        ) || {}
      );
    },
    isCashPayment() {
      return this.paymentMethodIndex === 9;
    },
    isPayByBalance() {
      return this.paymentMethodIndex === 10;
    },
    isPayByOther() {
      return this.paymentMethodIndex === 11;
    },
    isMobilePayment() {
      const mobilePaymentIndex = [5, 6, 7, 8];
      return mobilePaymentIndex.includes(this.paymentMethodIndex);
    },
    previewLang() {
      return {
        en: {
          greetings: ",",
          titlePartA: "Please see below for your statement for",
          titlePartB: ":",
          column: {
            student: "Name",
            item: "Item",
            amount: "Amount"
          },
          total: "Total",
          paymentForBalance:
            "You may use this link to check your statement details.",
          anyIssue: "If you have any questions regarding this statement, please click here to let us know:",
          paymentPartA:
            "Afterwards, please make a payment of ",
          paymentPartB: "",
          paymentPartC: " to the following account ",
          paymentPartD: ":",
          cashPaymentPartB:
            " at our Ivy-Way office ",
          cashPaymentPartC: ".",
          note: "Note: ",
          remark:
            "If you have any questions, please just let us know. Thank you!",
          issue: "Issues about the statement?",
          check: "See your statement",
          pay: "Please click here after you make a payment",
          accountName: "Account name: ",
          bank: "Bank: ",
          account: "Account: ",
          accountNumber: "Account number: ",
          balance: "儲值餘額",
          balanceLeft: "剩餘餘額"
        },
        tw: {
          greetings: " 您好",
          titlePartA: "以下是您的",
          titlePartB: " 帳單：",
          column: {
            student: "學生",
            item: "項目",
            amount: "金額"
          },
          total: "總金額",
          paymentForBalance: "您可以由此連結查看帳單明細。",
          anyIssue: "如果您對帳單有問題，請點擊這個連結告訴我們：",
          paymentPartA: "請在 ",
          paymentPartB: " 將",
          paymentPartC: " 匯款至下方帳戶：",
          paymentPartD: "",
          cashPaymentPartB: " 至Ivy-Way門市臨櫃進行付款。",
          cashPaymentPartC: "",
          note: "備註：",
          remark:
            "如果有任何問題，歡迎與我們聯繫，感謝您。",
          issue: "對帳單有問題？",
          check: "查看帳單明細",
          pay: "填寫付款資訊",
          accountName: "戶名：",
          bank: "銀行：",
          account: "帳號：",
          accountNumber: "銀行帳號：",
          balance: "儲值餘額",
          balanceLeft: "剩餘餘額"
        },
        cn: {
          greetings: " 您好",
          titlePartA: "以下是您的",
          titlePartB: " 帐单：",
          column: {
            student: "学生",
            item: "项目",
            amount: "金额"
          },
          total: "总金额",
          paymentForBalance: "您可以由此连结查看帐单明细。",
          anyIssue: "如果您对帐单有问题，请点击这个连结告诉我们：",
          paymentPartA: "请在 ",
          paymentPartB: " 将",
          paymentPartC: " 汇款至下方帐户：",
          paymentPartD: "",
          cashPaymentPartB: " 至Ivy-Way门市临柜进行付款。",
          cashPaymentPartC: "",
          note: "备注：",
          remark:
            "如果有任何问题，欢迎与我们联系，感谢您。",
          issue: "对帐单有问题？",
          check: "查看帐单明细",
          pay: "填写付款资讯",
          accountName: "户名：",
          bank: "银行：",
          account: "帐号：",
          accountNumber: "银行帐号：",
          balance: "储值余额",
          balanceLeft: "剩余余额"
        }
      };
    }
  },
  methods: {
    filterSelected(orderItems) {
      return orderItems.filter(
        orderItem => orderItem.selectType !== "unSelected"
      );
    },
    moment() {
      return moment();
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  border: 1px solid rgb(220, 223, 230);
  padding: 12px;
  border-radius: 4px;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;

  .logo {
    height: 60px;
  }
}

table {
  line-height: 40px;
  border-top: 1px solid rgb(212, 212, 212);
  border-collapse: collapse;
  width: 100%;

  tbody:first-child {
    tr {
      font-weight: bold;
      border-bottom: 1px solid rgb(212, 212, 212);
    }
  }

  tbody:last-child {
    tr {
      border-top: 1px solid rgb(212, 212, 212);
    }
  }
}

.remark {
  margin-bottom: 12px 0px;
}

.payment {
  padding: 9.5px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 12px;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 14px;
  }

  button {
    color: #fff;
    margin-left: 4px;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
  }
}
</style>
